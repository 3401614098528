<template>
    <div class="page">
        <bf-header/>
        <div class="start-screen">
            <div class="widget">
                <div class="description">
                    Someone is already controlling this screen right now.<br/><br/>
                    Would you like to take over?
                </div>

                <div class="buttons">
                    <div>
                        <div class="button solid" @click="go()">
                            Yes, continue
                        </div>
                    </div>
                    <div>
                        <div class="button cancel solid white" @click="$router.push('/')">
                            cancel
                        </div>
                    </div>
                </div>
            </div>

        </div>

    </div>
</template>

<script>
import BfHeader from "@/components/bf-header";

export default {
  name: "start",
  components: {BfHeader},
  data() {
    return {}
  },

  methods: {
    go() {
      this.$router.push({
        path: '/select',
        query: {
          lang: this.$route.query['lang'],
          ci: this.$route.query['ci'],
          si: this.$route.query['si'],
          override: true
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">

.start-screen {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 1em;
    font-size: 1.3em;
}

.description {
    font-weight: 400;
}

.buttons {
    margin-top: 2em;
    text-align: center;

    .cancel {
        opacity: .8;
        margin-top: 0.5em;
        box-shadow: none;
    }

}


</style>