<template>
    <div>
        <div id="header">

            <div class="header-content heading">
                Director
                <div class="logo-scala"/>
            </div>

            <div class="vw-line">
                <svg aria-label="Volkswagen" role="img" :width="logoSize" :height="logoSize" viewBox="0 0 24 24"
                     xmlns="http://www.w3.org/2000/svg" fill="currentColor"><title>Volkswagen</title>
                    <path d="M12 22.586c-5.786 0-10.543-4.8-10.543-10.586 0-1.2.214-2.357.6-3.471l6.172 12c.085.171.171.3.385.3.215 0 .3-.129.386-.3l2.871-6.386c.043-.086.086-.129.129-.129.086 0 .086.086.129.129l2.914 6.386c.086.171.171.3.386.3.214 0 .3-.129.385-.3l6.172-12c.385 1.071.6 2.228.6 3.471-.043 5.786-4.8 10.586-10.586 10.586zm0-13.329c-.086 0-.086-.086-.129-.128l-3.3-7.115a10.117 10.117 0 0 1 6.858 0l-3.3 7.115c-.043.042-.043.128-.129.128zm-3.471 7.714c-.086 0-.086-.085-.129-.128L3 6.47c.943-1.542 2.314-2.828 3.9-3.728l3.814 8.228c.086.172.172.215.3.215h1.972c.128 0 .214-.043.3-.215l3.771-8.228c1.586.9 2.957 2.186 3.9 3.728L15.6 16.843c-.043.086-.086.128-.129.128-.085 0-.085-.085-.128-.128L13.286 12.3c-.086-.171-.172-.214-.3-.214h-1.972c-.128 0-.214.043-.3.214l-2.057 4.543c-.043.043-.043.128-.128.128zM12 24c6.643 0 12-5.357 12-12S18.643 0 12 0 0 5.357 0 12s5.357 12 12 12z"></path>
                </svg>
            </div>
            <!--        </div>-->

            <transition name="fade-in">
                <div class="connected" v-if="connected" @click.stop>
                    <div class="text">
                        connected
                    </div>
                </div>
            </transition>
            <!--                </div>-->

        </div>
        <div class="header-spacer"></div>
    </div>
</template>

<script>
import ScLogo from "@/components/sc-logo";

export default {
  name: "bf-header",

  props: {
    connected: {
      default: false,
    },
    logoSize: {
      default: 48,
    }
  },

  components: {ScLogo},
  methods: {
    toStart() {
      this.$router.push({path: '/', query: {}})
    }
  }
}
</script>

<style scoped lang="scss">

#header {
    position: fixed;
    display: flex;
    flex-flow: column;
    padding: 1.5em 0 2em;
    color: var(--vw-color);
    height: 150px;
    box-sizing: border-box;
    background-color: #FFF;
    z-index: 100;

    //background: radial-gradient(circle at 0% 0%, rgb(0, 67, 122) 0%, rgb(0, 30, 80) 100%);

    .f3ae74be-4850-457d-96e6-4335d9bc2a65 {
        fill: none;
        stroke: currentColor;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-width: 4px;
    }

    .header-content {
        flex: 1;
        display: flex;
        flex-flow: column;
        font-size: 2em;
        //line-height: 140%;
        width: 100%;
        padding: 0 .5em;
        margin-bottom: .25em;
        position: relative;

        .logo-scala {
            position: absolute;
            top: 30px;
            height: 20px;
            widtH: 100px;

            background-image: url("~@/img/powered-by-scala.png");
            background-size: contain;
            background-repeat: no-repeat;
        }

    }

    .vw-line {
        --size: 32px;
        display: grid;
        box-sizing: border-box;
        grid-template-columns: calc(75vw - var(--size)) calc(var(--size) * 2) calc(25vw - var(--size));
        place-items: center;
        position: relative;

        &:before, &:after {
            content: '';
            margin-top: 4px;
            height: 2px;
            background: currentColor;
            width: 100%;
        }

        svg {
            margin: 0 .5em;
        }
    }
}

.header-spacer {
    height: 150px;
}

.fade-in-enter-active {
    transition: all 0.4s ease-in-out;

    .text {
        transition: all 0.5s ease-in-out;
    }
}

.fade-in-enter .text {
    opacity: 0;
}

.connected {
    color: var(--vw-color);
    animation: appear 0.5s ease-in-out 1.5s forwards;
    position: fixed;
    top: 150px;
    right: 0;
    height: calc(100% - 150px);
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--vw-color-light);

    .text {
        box-sizing: border-box;
        animation: pulse 0.5s ease-in-out 1.5s forwards;
        font-size: 1.8em;
        text-align: center;
        margin-top: -2px;
    }
}

@keyframes appear {
    60% {
        border-radius: 0;
    }
    100% {
        width: 7em;
        height: 22px;
        top: 2.25em;
        right: 1em;
        border-radius: 10em;
    }
}

@keyframes pulse {
    //0% {
    //    transform: scale(1);
    //}
    //50% {
    //    transform: scale(1.1);
    //}
    //70% {
    //    transform: scale(1.1);
    //}
    100% {
        font-size: 1em;
    }
}
</style>