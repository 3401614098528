<template>
    <div class="root" v-if="ready">
        <notification-panel></notification-panel>
        <router-view></router-view>
    </div>
</template>

<script>
export default {
  name: 'root',
  data() {
    return {
      ready: false
    }
  },

  mounted() {
    if (process.env.VUE_APP_TEST_MODE === 'true') {
      return
    }

    this.$nextTick(() => {
      this.ready = true;

      if (this.$route.query['ci'] && this.$route.query['si'] && this.$route.query['lang']) {
        this.$router.push({
          path: '/select',
          query: {
            lang: this.$route.query['lang'],
            ci: this.$route.query['ci'],
            si: this.$route.query['si']
          }
        })
      } else {
        this.$router.push('/').catch(err => {})
      }
    })
  }
}
</script>

<style lang="scss">
@font-face {
    font-family: 'VW-Text';
    src: url('~@/assets/fonts/VWText-Regular.otf') format('opentype');
    //font-weight: normal;
    //font-style: normal;
}

@font-face {
    font-family: 'VW-Head';
    src: url('~@/assets/fonts/VWHead-Bold.otf') format('opentype');
    //font-weight: bold;
}


@import '@/design/_reset';
@import '@/design/_globals';



</style>

<style scoped lang="scss">

.root {
    height: 100%;
}
</style>