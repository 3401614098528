<template>
    <div class="page">
        <bf-header/>
        <div class="start-screen">
            <div class="widget">
                <div class="description">
                    Scan a <b>QR</b> code to connect
                </div>
                <div class="buttons">
                    <div class="button solid white" @click="$router.push('/scan')">
                        Open Camera
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ScLogo from "@/components/sc-logo";
import BfHeader from "@/components/bf-header";

export default {
  name: "start",
  components: {BfHeader, ScLogo},
}
</script>

<style scoped lang="scss">

.page {
    overflow: hidden;
}

.start-screen {
    flex: 1 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1em;
    font-size: 1.2em;
    box-sizing: border-box;

    .widget {
        background-color: var(--vw-gradient-circle);
    }

    .buttons {
        margin-top: 1em;
        text-align: center;
    }
}
</style>